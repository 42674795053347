import React, { Component } from 'react';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';

import section1 from "./components/images/section1.png";
import section1video from "./components/senotrix.com-video-final.mp4";
import section3a from "./components/images/section3a.png";
import section3b from "./components/images/section3b.png";
import section4 from "./components/images/section4.png";
import comment from "./components/images/comment.png";
import digitalmarketing from "./components/images/digitalmarketing.png";
import businesslisting from "./components/images/businesslisting.png";
import tick from "./components/images/tick.png";

import google from "./components/images/google.png";
import trustpilot from "./components/images/trustpilot.jpg";

class Animation1 extends React.Component {
  state = {
    transform: 'translateX(+350px)'
  }

  listenScrollEvent = e => {
    if (window.scrollY > 180) {
      this.setState({transform: 'translateX(-200px)'})
    } else {
      this.setState({transform: 'translateX(+350px)'})
    }
    if (window.scrollY > 450) {
      this.setState({transform: 'translateX(-200px)'})
    } else {
      this.setState({transform: 'translateX(+350px)'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() { 
    return (
      <div>
        <img src={section1} class="animatedimg1" alt="ppcads" style={{ transform: this.state.transform }} /> 
      </div>
    );
  }
};

class Animation1mobile extends React.Component {
  state = {
    transform: 'translateX(+250px)'
  }

  listenScrollEvent = e => {
    if (window.scrollY > 180) {
      this.setState({transform: 'translateX(-30px)'})
    } else {
      this.setState({transform: 'translateX(+250px)'})
    }
    if (window.scrollY > 450) {
      this.setState({transform: 'translateX(-30px)'})
    } else {
      this.setState({transform: 'translateX(+250px)'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() { 
    return (
      <div>
        <img src={section1} class="animatedimg1" alt="ppcads" style={{ transform: this.state.transform }} /> 
      </div>
    );
  }
};

class Animation2 extends React.Component {
  state = {
    transform: 'translateX(+300px)'
  }

  listenScrollEvent = e => {
    if (window.scrollY > 1100) {
      this.setState({transform: 'translateX(-200px)'})
    } else {
      this.setState({transform: 'translateX(+300px)'})
    }
    if (window.scrollY > 1250) {
      this.setState({transform: 'translateX(-200px)'})
    } else {
      this.setState({transform: 'translateX(+300px)'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() { 
    return (
      <div>
        <img src={section3a} class="animatedimg2" alt="social media" style={{ transform: this.state.transform }} /> 
      </div>
    );
  }
};

class Animation2mobile extends React.Component {
  state = {
    transform: 'translateX(+250px)'
  }

  listenScrollEvent = e => {
    if (window.scrollY > 1100) {
      this.setState({transform: 'translateX(-20px)'})
    } else {
      this.setState({transform: 'translateX(+250px)'})
    }
    if (window.scrollY > 1250) {
      this.setState({transform: 'translateX(-20px)'})
    } else {
      this.setState({transform: 'translateX(+250px)'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() { 
    return (
      <div>
        <img src={section3b} class="animatedimg2mobile" alt="social media" style={{ transform: this.state.transform }} /> 
      </div>
    );
  }
};

function App() {
  return ( 
    <div className="App">
      <Header />
      <div class="container-fuid">
        <video id="promotionvideo" autoplay='1' muted='1' loop>
          <source src={ section1video } type="video/mp4" />
        </video>
        <div class="row section1 align-items-center">
          <div class="col-lg-12" id="sec1col1">
            <h1>Building Brands.</h1>
            <br />
            <h1>Driving New Leads.</h1>
            <br />
            <h1>Growing Small Businesses.</h1>

            <div id="badges" style={{ display: 'flex', marginTop: '20px' }}>
              <a href="https://www.google.com/partners/agency?id=2687112666" target="_blank"><img src={ google } style={{ width: "200px", display: 'block' }} alt="" /></a>
              <a href="https://uk.trustpilot.com/review/senotrix.co.uk" target="_blank"><img src={ trustpilot } style={{ maxWidth: '200px', marginLeft: '35px', display: 'block' }} alt="" /></a>
            </div>
          </div>
        </div>

        <div class="row section2 align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5" id="leftcontent">
            <span>PPC ADS</span>
            <h2>Drive more leads to your business</h2>
            <a href="/ppc-ads/" class="customlink">Learn More ></a>
          </div>
          <div class="col-lg-5" id="rightcontent">
              < Animation1 />
          </div>
          <div class="col-lg-1"></div>          
        </div>

        <div class="row section2mobile align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5" id="leftcontent">
            <span>PPC ADS</span>
            <h2>Drive more leads to your business</h2>
            <a href="/ppc-ads/" class="customlink">Learn More ></a>
          </div>
          <div class="col-lg-5" id="rightcontent">
              < Animation1mobile />
          </div>
          <div class="col-lg-1"></div>          
        </div>

        <div class="row section3 align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5" id="leftcontent">
            <span>WEBSITE DESIGN</span>
            <h2>Stand out from the crowd</h2>
            <a href="/website-design/" class="customlink">Learn More > </a>
          </div>
          <div class="col-lg-5" id="rightcontent">
          </div>
          <div class="col-lg-1"></div>          
        </div>

        <div class="row section4 align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5" id="leftcontent">
            <span>SOCIAL MEDIA</span>
            <h2>Engage with your audience</h2>
            <a href="/social-media/" class="customlink">Learn More ></a>
          </div>
          <div class="col-lg-5" id="rightcontent">
            <Animation2 />
          </div>
          <div class="col-lg-1"></div>          
        </div>

        <div class="row section4mobile align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5" id="leftcontent">
            <span>SOCIAL MEDIA</span>
            <h2>Engage with your audience</h2>
            <a href="/social-media/" class="customlink">Learn More ></a>
          </div>
          <div class="col-lg-5" id="rightcontent">
            <Animation2mobile />
          </div>
          <div class="col-lg-1"></div>          
        </div>

        <div class="row section5 align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5" id="leftcontent">
            <span>FREE ONLINE TOOL</span>
            <h2>Check your SEO score for free</h2>
            <a href="/search-engine-optimisation/" class="customlink">Learn More ></a>
          </div>
          <div class="col-lg-5" id="rightcontent">
          <img src={section4} alt="seo" />
          </div>
          <div class="col-lg-1"></div>          
        </div>

        <div class="row section6 align-items-center">
          <div class="col-lg-4 col-xl-3" id="left">
            <img src={comment} alt="" />
            <h3>Free <span style={{color: "#6648F6"}}>121</span> reconnaisance session</h3>
            <p>Via video chat and screenshare with our experienced project managers</p>
            <a href="/contact/" class="customlink">Get a 1-2-1 reconnaisance session ></a>
          </div>
          <div class="col-lg-4 col-xl-3" id="center">
            <img src={digitalmarketing} alt="" />
            <h3><span style={{color: "#F79629"}}>Brainstorming</span> for ideas & competition analysis</h3>
            <p>Learn how to easily improve your own online presence, only with Senotrix</p>
            <a href="/contact/" class="customlink">Learn more ></a>
          </div>
          <div class="col-lg-4 col-xl-3" id="right">
            <img src={businesslisting} alt="" />
            <h3>Rank Higher in <span style={{color: "#10C0CA"}}> local map listings</span></h3>
            <p>Rank higher in your local area map listings with Google My Businesss</p>
            <a href="/business-listings/" class="customlink">Get started ></a>
          </div>     
        </div>
        <div class="calltoaction align-items-center">
            <h2>Need help? Contact our friendly team</h2>
            <button type="button"><a href="/contact/" style={{color: "#fff"}}>Contact us</a></button>
        </div>
        <div class="row prefooter">
          <div class="col-lg-3" id="first">
            <span>Why Senotrix?</span>
          </div>
          <div class="col-lg-3">
            <span><img src={tick} alt="" /> Full Transparency</span>
            <p>Online dashboard tracking phone call enquiries</p>
          </div>
          <div class="col-lg-3">
            <span><img src={tick} alt="" /> Trusted & Endorsed</span>
            <p>By the world's most popular search engines</p>
          </div>
          <div class="col-lg-3">
            <span><img src={tick} alt="" /> Highly Rated</span>
            <p>4.6 rating on TrustPilot and thousands of customers</p>
          </div>
        </div>                 
      </div>

      <Footer />
    </div>
  );
}

export default App;
