import React, { Component } from 'react';

import logo from "./images/senotrixlogo.png";
import webdesign from "./images/webdesign.png";
import webdesignh from "./images/hover/webdesign.png";
import ppcads from "./images/ppcads.png";
import ppcadsh from "./images/hover/ppcads.png";
import socialmedia from "./images/socialmedia.png";
import socialmediah from "./images/hover/socialmedia.png";
import listings from "./images/listings.png";
import listingsh from "./images/hover/listings.png";
import seo from "./images/seo.png";
import seoh from "./images/hover/seo.png";
import facebook from "./images/facebook.png";
import twitter from "./images/twitter.png";
import linkedin from "./images/linkedin.png";
import instagram from "./images/instagram.png";


import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
.ml-auto, .mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.App nav.bg-dark {
  background-color: #000 !important;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 2;
}
.App li a.nav-link {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 2px;
}
.App li {
  margin-right: 20px;
}
.navbar-dark .navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .active, .navbar-dark .navbar-nav .nav-link:hover {
  color: #F79629;
}
.navbar-dark .navbar-nav .nav-link img#hoverimg, .navbar-dark .navbar-nav .nav-item.active img#hoverimg {
  display: none;
}
.navbar-dark .navbar-nav .nav-link:hover img#hoverimg, .navbar-dark .navbar-nav .nav-item.active img#hoverimg {
  display: inline-block;
}
.navbar-dark .navbar-nav .nav-link:hover img#normal, .navbar-dark .navbar-nav .nav-item.active img#normal {
  display: none;
}
span.social {
  display: flex;
}
span.social li {
  margin-right: 5px;
}
span.social li a {
  display: -webkit-inline-box;
}
span.custommenu {
  display: flex;
}
li#web a:hover, li#web.nav-item.active a {
  color: #69D0F1;
}
li#ppc a:hover, li#ppc.nav-item.active a {
  color: #6648F6;
}
li#socialmedia a:hover, li#socialmedia.nav-item.active a {
  color: #FC503C;
}
li#listings a:hover, li#listings.nav-item.active a  {
  color: #45ADAF;
}
li#seo a:hover, li#seo.nav-item.active a {
  color: #F79629;
}



@media (max-width: 991px) {
  ul.navbar-nav {
    padding: 20px 0;
  }
  span.custommenu {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .App li a.nav-link {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 2px;
  }
  span.social {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: -1.5%;
  }
  li#contact {
    margin-top: 20px;
  }
  li#contactinfo {
    margin-bottom: 20px;
  }
  li#contactinfo a {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;    
  }
  li#contactinfo a span {
    color: #f79629;
  }
}
.modal-backdrop {
  width: auto;
  height: auto;
}
.modal-header {
  border-bottom-color: #fff;
}
h4.modal-title {
  color: #000000;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  margin: 20px 0;
  text-align: center;
}
div.modal-body p {
  text-align: center;
}
form#popupform {
  margin: 40px 0 80px 0;
}
form#popupform input.name, form#popupform input.email, form#popupform input.phone, form#popupform input.business {
  width: 48%;
  height: 50px;
  margin: 5px;
  padding: 6px 16px;
}
form#popupform textarea {
  width: 97.5%;
  margin: 5px;
  padding: 6px 16px;
}
form#popupform span {
    width: 100%;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 5px;
}
form#popupform input#ebcaptchainput {
  height: 50px;
  display: block;
}
form#popupform label#ebcaptchatext {
  margin-top: 10px;
}
form#popupform button#formbtn {
  background-color: #6BC8E1;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;    
  border-radius: 0px;
  padding: 10px 30px;
  border: 1px solid #6BC8E1;
  text-transform: uppercase;
  display: block;
  margin: 10px auto;
  letter-spacing: 1px;
}
form#popupform button#formbtn:hover {
  opacity: 0.8;
}
@media (min-width: 576px) {
.modal-dialog {
    max-width: 51%;
}
}
@media (max-width: 991px) {
  form#popupform input.name, form#popupform input.email, form#popupform input.phone, form#popupform input.business {
    width: 98%;
  }
  #formrow {
    padding-left: 6%;
  }
  form#popupform {
    margin: 20px 0 40px 0;
  }
  h4.modal-title {
    font-size: 26px;
    line-height: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1045px) {
  form#popupform input.name, form#popupform input.email, form#popupform input.phone, form#popupform input.business {
    width: 45%;
  }
  form#popupform textarea {
    width: 92%;
  }
}
@media (min-width: 992px) {
  span.social {
    position: absolute;
    right: 12px;
  }
  li#contactinfo {
    display: none;
  }
  #formrow {
    padding-left: 3%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  span.custommenu {
    position: relative;
    left: -12%;
    right: 10%;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .App li a.nav-link {
    font-size: 11px;
    line-height: 18px;
  }
}
@media (min-width: 1200px) {
  span.custommenu {
      position: relative;
      right: 10%;
  }
}

`;

function Header() {
    return (
        <section>
            <GlobalStyle />
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                <div class="container-fluid">
                <a class="navbar-brand" href="/">
                        <img src={logo} alt="logo" />
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarresponsive"
                    aria-controls="navbarresponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarresponsive">
                    <ul class="navbar-nav ml-auto">
                    <span class="custommenu">
                        <li class="nav-item" id="web">
                        <a class="nav-link" href="/website-design">
                        <img src={ webdesign } alt="" id="normal" /><img src={ webdesignh } alt="" id="hoverimg" /> Web Design
                        </a>
                        </li>
                        <li class="nav-item" id="ppc">
                        <a class="nav-link" href="/ppc-ads">
                            <img src={ ppcads } alt="" id="normal" /><img src={ ppcadsh } alt="" id="hoverimg" />  PPC Ads
                        </a>
                        </li>
                        <li class="nav-item" id="socialmedia">
                        <a class="nav-link" href="/social-media">
                            <img src={ socialmedia } alt="" id="normal" /> <img src={ socialmediah } alt="" id="hoverimg" /> Social Media
                        </a>
                        </li>
                        <li class="nav-item" id="listings">
                        <a class="nav-link" href="/business-listings">
                        <img src={ listings } alt="" id="normal" /><img src={ listingsh } alt="" id="hoverimg" />  Listings
                        </a>
                        </li>
                        <li class="nav-item" id="seo">
                        <a class="nav-link" href="/search-engine-optimisation">
                            <img src={ seo } alt="" id="normal" /><img src={ seoh } alt="" id="hoverimg" />  SEO
                        </a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="/about">
                            About Us
                        </a>
                        </li>
                        <li id="contact" class="nav-item" >
                        <a href="#" class="nav-link" data-toggle="modal" data-target="#popup">Contact</a>
                        <div class="modal fade" id="popup" role="dialog">
                            <div class="modal-dialog modal-md modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header font-weight-bolder">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                    <h4 class="modal-title">Call +1 888-557-3088, or fill in the form</h4>
                                    <p>By submitting this form, you will be sharing your contact details with Senotrix.</p>
                                    <form action="/senotrixemail.php" method="post" id="popupform">
                                        <input class="name" type="text" name="name" placeholder="Your Name" pattern="[a-zA-Z][a-zA-Z ]{2,69}" title="Name must include minimum 3 and maximum 70 characters. Numbers or special characters not allowed." required /> 
                                        <input class="email" type="email" name="email" placeholder="Email Address" required />
                                        <input class="phone" type="tel" name="phone" placeholder="Phone Number" required />
                                        <input class="business" type="text" name="business" placeholder="Business Name" pattern="{1,69}" title="Business Name must include minimum 2 and maximum 70 characters." required />
                                        <textarea rows="5" name="message" placeholder="Message"></textarea>
                                        <span>Enquiry : <br /></span>
                                        <div class="row" id="formrow">
                                        <div class="col-lg-3">
                                            <label class="form-check-label" for="radio1">
                                            <input type="radio" class="form-check-input" id="radio1" name="enquiry" value="Web Design" />Web Design
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <label class="form-check-label" for="radio2">
                                            <input type="radio" class="form-check-input" id="radio2" name="enquiry" value="Digital Marketing" />Digital Marketing
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <label class="form-check-label">
                                            <input type="radio" class="form-check-input" id="radio3" name="enquiry" value="1-2-1 Audit" /> 1-2-1 Audit
                                            </label>
                                        </div>  
                                        <div class="col-lg-3">
                                            <label class="form-check-label">
                                            <input type="radio" class="form-check-input" id="radio3" name="enquiry" value="Customer Services" />Customer Services
                                            </label>
                                        </div>
                                        </div>
                                        <label id="ebcaptchatext"></label>
                                        <input type="text" class="textbox" id="ebcaptchainput" required />
                                    <button type="submit" id="formbtn">Submit</button>
                                    </form>        
                                </div>
                            </div>
                            </div>
                            </div>
                        </li>                
                        <li id="contactinfo">
                        <a href="tel: 18885573088"><span>tel</span> +1 888-557-3088</a><br />
                        <a href="mailto: info@senotrix.com"><span>email</span> info@senotrix.com</a>
                        </li>
                    </span>
                    <span class="social">
                        <li>
                            <a href="https://www.facebook.com/senotrixltd" target="_blank">
                            <img src={facebook} alt="logo" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/senotrixltd" target="_blank">
                            <img src={twitter} alt="logo" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/senotrixltd/" target="_blank">
                            <img src={linkedin} alt="logo" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/senotrixltd/" target="_blank">
                            <img src={instagram} alt="logo" />
                            </a>
                        </li>
                    </span>
                    </ul>
                </div>
                </div>
            </nav>
        </section>    
  );
}

export default Header;