import React, { Component } from 'react';

import mobile from "./images/mobile.png";
import email from "./images/email.png";
import address from "./images/address.png";
import facebook from "./images/facebook.png";
import twitter from "./images/twitter.png";
import linkedin from "./images/linkedin.png";
import instagram from "./images/instagram.png";
import google from "./images/google.png";
import trustpilot from "./images/trustpilot.jpg";

import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`

footer div.upperpart {
  background: #3A3A3C;
  padding: 60px 0 60px 0;
  margin-left: auto;
  margin-right: auto;
}
footer div.upperpart p.footertitle {
  font-family: 'Montserrat', sans-serif;
  color: #269ABF;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 15px;
}
footer div.upperpart span.contactdetails img {
  margin-top: -5px;
}
footer div.upperpart span.contactdetails a, footer div.upperpart span {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    display: block;
}
footer div.upperpart span.contactdetails a:hover {
  text-decoration: none;
  opacity: 0.55;
}
footer div.upperpart ul.links li a {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: #fff;
}
footer div.upperpart ul.links li a:hover {
  text-decoration: none;
  opacity: 0.55;
}
footer div.upperpart ul.links li {
  list-style-type: none;
  margin: 0 0 0 -20px;
}
span.socialfooter {
  display: block;
  margin-top: 20px;
}
span.socialfooter a {
  margin-right: 5px;
  display: -webkit-inline-box;
}
footer div.copyright {
  background: #000;
  padding: 10px;
}
footer div.copyright p {
  font-size: 18px;
  line-height: 1;
}

@media (min-width: 992px) and (max-width: 1148px) {
  footer div.upperpart {
    padding: 60px 5% 60px 5%;
  }
}
@media (min-width: 1149px) {
  footer div.upperpart {
    padding: 60px 12.5% 60px 12.5%;
  }
}
`;

function Footer() {
    return (
        <section>
            <GlobalStyle />
            <footer>
                <div class="row upperpart">
                    <div class="col-lg-3">
                        <p class="footertitle">Head Office</p>
                        <span class="contactdetails"><a href="tel:+44(0)203 950 9894"><img src={mobile} alt="" /> +44(0)203 950 9894</a></span>
                        <span class="contactdetails"><a href="mailto: info@senotrix.co.uk"><img src={email} alt="" /> info@senotrix.co.uk</a></span>
                        <span class="contactdetails"><a href="https://g.page/senotrixltd?share" target="_blank"><img src={address} style={{ width: '20px' }} alt="" /> 24 Holborn Viaduct, London EC1A 2BN, UK</a></span>
                            <span class="socialfooter">
                                <a href="https://www.facebook.com/senotrixltd" target="_blank">
                                <img src={facebook} alt="logo" />
                                </a>
                                <a href="https://twitter.com/senotrixltd" target="_blank">
                                <img src={twitter} alt="logo" />
                                </a>
                                <a href="https://www.linkedin.com/company/senotrixltd/" target="_blank">
                                <img src={linkedin} alt="logo" />
                                </a>
                                <a href="https://www.instagram.com/senotrixltd/" target="_blank">
                                <img src={instagram} alt="logo" />
                                </a>
                            </span>
                        </div>
                        <div class="col-lg-3">
                            <p class="footertitle">For US Clients</p>
                            <p class="footertitle">New York</p>
                            <span class="contactdetails"><a href="tel:+14157120248"><img src={mobile} alt="" /> +1(415) 712-0248</a></span>
                            <span class="contactdetails"><img src={address} style={{ width: '20px' }} alt="" /> 1532 Ocean Ave, Brooklyn, NY 11230</span>
                            <span class="contactdetails"><a href="mailto: info@senotrix.com"><img src={email} alt="" /> info@senotrix.com</a></span>
                            <p class="footertitle">Los Angeles</p>
                            <span class="contactdetails"><a href="tel:+13477194447"><img src={mobile} alt="" /> +1(347) 719-4447</a></span>
                            <span class="contactdetails"><img src={address} style={{ width: '20px' }} alt="" /> 16815 kingsbury st los angeles 91344 us</span>
                        </div>
                        <div class="col-lg-3">
                            <p class="footertitle">Quick Links</p>
                            <ul class="links">
                            <li><a href="/website-design">Web Design</a></li>

                            <li><a href="/ppc-ads">PPC Ads</a></li>

                            <li><a href="/social-media">Social Media</a></li>

                            <li><a href="/business-listings">Listings</a></li>

                            <li><a href="/search-engine-optimisation">SEO</a></li>

                            <li><a href="/about">About Us</a></li>

                            <li><a href="/contact">Contact</a></li>                                    
                            </ul>
                        </div>
                        <div class="col-lg-3">
                            <p class="footertitle">Proud Partners</p>
                            <a href="https://www.google.com/partners/agency?id=2687112666" target="_blank"><img src={ google } style={{ width: "200px", display: 'block' }} alt="" /></a>
                            <a href="https://uk.trustpilot.com/review/senotrix.co.uk" target="_blank"><img src={ trustpilot } style={{ maxWidth: '200px', marginTop: '20px', display: 'block' }} alt="" /></a>
                        </div>
                    </div>
                    <div class="copyright">
                        <div class="container">
                            <p class="m-0 text-center text-white">
                            2021 &copy; All Rights Reserved.
                            </p>
                        </div>
                    </div>
            </footer>
        </section>    
  );
}

export default Footer;